.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{

  /* background: rgb(145,148,91); */
/* background: linear-gradient(90deg, rgba(145,148,91,1) 0%, rgba(34,11,41,1) 0%, rgba(111,81,19,1) 100%); */
    /* background: rgb(23,22,61);
    background: linear-gradient(90deg, rgba(23,22,61,1) 26%, rgba(150,22,22,1) 100%);
      color : black; */

    background : white; 

}


.transparent{
  background-color: rgba(0,0,0,0);;
}


#landing-page-wrapper{
  /* background: rgb(23,22,61);
  background: linear-gradient(90deg, rgba(23,22,61,1) 26%, rgba(150,22,22,1) 100%); */
  background : white; 
}
.nav-wrapper{
  background-color: transparent;

  position: absolute;
  z-index:999999999;
}


/* label focus color */
.input-field input:focus + label {
  color: #4f2d69 !important;
}
/* label underline focus color */
.row .input-field input:focus {
  border-bottom: 1px solid #4f2d69 !important;
  box-shadow: 0 1px 0 0 #4f2d69 !important
}

#landing-image{
    width: 180px;
    height: auto;
}

.aboutclass{
  font-family: "Courier New"
}

.dg.ac {
  z-index: 999!important;
}

.example{
  position: absolute !important;
  top : 0;
  left : 0;
  height : 100%;
  width : 100%

}